/* eslint-disable react-hooks/rules-of-hooks */
import { Link, Box, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { GetCookie, SetCookie } from "../provider/common";

export default function popUp() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // 기본을 미노출로 한다.
  const [visible, setVisible] = useState(false);
  const date = new Date();
  const today = date.toISOString().slice(0, 10);

  const closeToday = async () => {
    await SetCookie("closePopUpDate", today);
    setVisible(false);
  };

  useEffect(async () => {
    const theDay = await GetCookie("closePopUpDate");
    // 두 날짜가 다르면 노출 초기값은 undefined 와 2024-07-16이라 노출
    // 오늘 하루 더 보이기 체크 하면 날짜가 같아서 계속 미노출
    if (theDay !== today) {
      setVisible(true);
    }
  }, []);

  return (
    <>
      <Box className="popUp modal">
        {visible ? (
          <>
            <Box className="popUp_box">
              <img
                src="./images/popup/popup_seol_sample_2025.png"
                width="100%"
                height="auto"
              />
            </Box>
            <Box className="popUp_close">
              <Link onClick={() => [setVisible(!visible)]}>X</Link>
            </Box>
            <Box className="popUp_txt">
              <input type="checkbox" onClick={closeToday}></input>
              <Link onClick={closeToday}>오늘 하루 열지 않기</Link>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
